import { Injectable } from "@angular/core";
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from "@angular/common/http";
import { catchError, switchMap } from "rxjs/operators";
import { from, throwError } from "rxjs";
import { PartnerService } from "./partner.service";
import { AESService } from "./aes.service";
import { RSAService } from "./rsa.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	// API URLs to exclude from encryption
	// - HTTP GET: if query param contains non-sensitive filtering or context only
	// - HTTP POST: if body payload contains non-sensitive data only.
	// Example: /api/Common/test
    excludeURLList = [
    ];

	encryptedAESKey: string;

	constructor(private partnerService: PartnerService,
		private aesService: AESService,
		private rsaService: RSAService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		try {
			let partnerCode: string = this.partnerService.getPartnerCode();
			if (partnerCode) {
				req = req.clone({ setHeaders: { partnerCode: partnerCode } });
			}

			let excludeFound = this.excludeURLList.filter(element => {
				return req.url.includes(element)
			});

			if (!(excludeFound && excludeFound.length > 0)) {
				// Adherence to RESTful conventions and best practices:
				// 1. Request body should not be included for HTTP GET, hence only encrypt query params
				if (req.method === "GET" && req.url.includes("?")) {

                    let aesKeyValue = this.aesService.aesKey();
					let encryptedParams = this.aesService.encrypt(req.url.split('?')[1]);
					let encryptedURL = req.url.split('?')[0] + '?' + encryptedParams;
					
					// Use switchMap to wait for the encryption to complete before proceeding
					return from(this.encryptAESKeyWithPublicKey(aesKeyValue)).pipe(
						switchMap(() => {
							// Clone the request with the encrypted URL and AES key in the header
							const clonedReq = req.clone({ 
								url: encryptedURL, 
								setHeaders: { 'X-Encrypted-AES-Key-QueryParam': this.encryptedAESKey } 
							});
							
							return next.handle(clonedReq);
						}),
						catchError((err) => {
							return throwError(err);
						})
					);
                }
            }

			return next.handle(req).pipe(
				catchError((err) => {
					console.error(err);
					if (err.status === 401) {
						err.error = "Session expired";
					} else if (err.status === 0) {
						err.error = "Unable connect to server.";
					}
					return throwError(err);
				}),
			);
		} catch (error) {
			return next.handle(req);
		}
	}

	private async encryptAESKeyWithPublicKey(aesKeyValue: string) {
        this.encryptedAESKey = await this.rsaService.encryptWithPublicKey(aesKeyValue);
    }
}
