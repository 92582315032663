import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { ThankyouPurchaseDialogComponent } from "../thankyou-purchase-dialog/thankyou-purchase-dialog.component";
import { PromoCodeInterface } from "src/app/share/interface/motor.interface";
import { HttpHeaders } from "@angular/common/http";

@Component({
	selector: "app-agent-code-dialog",
	templateUrl: "./agent-code-dialog.component.html",
	styleUrls: ["./agent-code-dialog.component.css"],
})
export class AgentCodeDialogComponent {
	id: string;
	headers: HttpHeaders;
	
	quotationId: string;
	selectedAddOn: any;
	addOnValue: any;
	infoValue: any;
	mykadOrPassport: any;
	letterOfUndertaking: any;
	vehiclePhoto: any;
	fgapAddOnsIndex: string;
	ewpAmount: any;
	ewpFiles: any;
	includeRoadTax: any;

	agentCode: string;
	promoCodeId: string;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: any,
		private transferOwnershipDataService: TransferOwnershipDataService,
		private ngxSpinnerService: NgxSpinnerService,
		private alertService: AlertService,
		private dialog: MatDialog,
		public dialogRef: MatDialogRef<AgentCodeDialogComponent>,
	) {
		this.quotationId = data.quotationId;
		this.selectedAddOn = data.selectedAddOn;
		this.addOnValue = data.addOnValue;
		this.infoValue = data.infoValue;
		this.mykadOrPassport = data.mykadOrPassport;
		this.letterOfUndertaking = data.letterOfUndertaking;
		this.vehiclePhoto = data.vehiclePhoto;
		this.fgapAddOnsIndex = data.fgapAddOnsIndex;
		this.ewpAmount = data.ewpAmount;
		this.ewpFiles = data.ewpFiles;
		this.includeRoadTax = data.includeRoadTax ? true : false;

		this.id = data.id;
		this.promoCodeId = data.promoCodeId;
		this.headers = data.headers;
	}

	submitAgentCode() {
		this.ngxSpinnerService.show();
		let fd = new FormData();

		fd.append("QuotationId", this.quotationId);
		if (
			this.selectedAddOn.find((e) => e.category == "Windshield") &&
			this.addOnValue.windshield
		) {
			fd.append("WindShield", this.addOnValue.windshield);
		}
		if (
			this.selectedAddOn.find((e) => e.category == "FGAP") &&
			this.fgapAddOnsIndex
		) {
			fd.append("Fgap.Value", this.fgapAddOnsIndex);
		}
		if (
			this.selectedAddOn.find((e) => e.category == "FGAP") &&
			this.addOnValue.fgap
		) {
			fd.append("Fgap.Amount", this.addOnValue.fgap);
		}
		if (this.selectedAddOn.find((e) => e.category == "EWP") && this.ewpAmount) {
			fd.append("Ewp.Value", this.addOnValue.ewp);
			fd.append("Ewp.Amount", this.ewpAmount);
		}
		if (this.ewpFiles.mileageImage.length) {
			fd.append(`Ewp.FileList[1].type`, "Mileage");
			fd.append(`Ewp.FileList[1].files`, this.ewpFiles.mileageImage[0]);
		}
		if (this.ewpFiles.vehRegCard.length) {
			fd.append(`Ewp.FileList[2].type`, "VehicleGrant");
			fd.append(`Ewp.FileList[2].files`, this.ewpFiles.vehRegCard[0]);
		}
		if (this.ewpFiles.vehInsReport.length) {
			fd.append(`Ewp.FileList[0].type`, "VehicleInspectionReport");
			fd.append(`Ewp.FileList[0].files`, this.ewpFiles.vehInsReport[0]);
		}

		if (this.infoValue.occupation) {
			fd.append("Occupation", this.infoValue.occupation);
		}

		fd.append("Address", this.infoValue.address);
		fd.append("Address2", this.infoValue.address2);
		fd.append("City", this.infoValue.city);
		fd.append("State", this.infoValue.state);
		fd.append("DeliveryAddress", this.infoValue.deliveryAddress);
		fd.append("DeliveryAddress2", this.infoValue.deliveryAddress2);
		fd.append("DeliveryCity", this.infoValue.deliveryCity);
		fd.append("DeliveryPostcode", this.infoValue.deliveryPostcode);
		fd.append("DeliveryState", this.infoValue.deliveryState);
		fd.append("IncludeRoadTax", this.addOnValue.isIncludeRoadtax ?? false);
		fd.append("Name", this.infoValue.name);
		fd.append("Email", this.infoValue.email);
		fd.append("MobileNo", this.infoValue.mobileNo);
		fd.append("FinanceType", this.infoValue.financingType);
		fd.append("Role", this.infoValue.dealerCase ? "Dealer" : "Internal");
		fd.append("AgentCode", this.agentCode);
		fd.append("Seat", this.addOnValue.lltpSeats);

		if (this.promoCodeId != null && typeof (this.promoCodeId != undefined)) {
			fd.append("PromoCodeId", this.promoCodeId);
		}

		if (this.infoValue.dealerCase) {
			fd.append(
				"DealerOutlet",
				this.infoValue.dealerOutlets !== "Others"
					? this.infoValue.dealerOutlets
					: this.infoValue.customDealerOutlet,
			);
		}
		if (this.infoValue.bank) {
			fd.append("HirePurchase", this.infoValue.bank);
		}
		if (this.infoValue.effectiveDate) {
			fd.append(
				"HirePurchaseEffectiveDate",
				this.infoValue.effectiveDate.toISOString(),
			);
		}

		if (
			this.selectedAddOn.find((e) => e.code == "AdditionalDrivers") &&
			this.addOnValue.drivers
		) {
			this.addOnValue.drivers.map((x, i) => {
				fd.append(`AdditionalDriverList[${i}].Dob`, x.dob.toISOString());
				fd.append(`AdditionalDriverList[${i}].Gender`, x.gender);
				fd.append(`AdditionalDriverList[${i}].IdentityNo`, x.identityNo);
				fd.append(`AdditionalDriverList[${i}].MaritalStatus`, x.maritalStatus);
				fd.append(`AdditionalDriverList[${i}].Name`, x.name);
				fd.append(`AdditionalDriverList[${i}].Relationship`, x.relationship);
			});
		}

		if (this.selectedAddOn.length) {
			this.selectedAddOn.map((x, i) => {
				fd.append(`AddOnIds[${i}]`, x.id);
			});
		}

		if (this.mykadOrPassport.length) {
			fd.append("FileList[0].type", "ID");
			this.mykadOrPassport.forEach((x) => {
				fd.append(`FileList[0].files`, x);
			});
		}

		if (this.letterOfUndertaking.length) {
			fd.append("FileList[1].type", "LOU");
			this.letterOfUndertaking.forEach((x) => {
				fd.append(`FileList[1].files`, x);
			});
		}

		if (this.vehiclePhoto.length) {
			fd.append("FileList[2].type", "VehiclePhoto");
			this.vehiclePhoto.forEach((x) => {
				fd.append(`FileList[2].files`, x);
			});
		}

		this.transferOwnershipDataService.submitOrder(this.id, fd).subscribe({
			next: (x) => {
				this.ngxSpinnerService.hide();
				let thankyouDialogRef = this.dialog.open(
					ThankyouPurchaseDialogComponent,
					{
						panelClass: "thankyou-purchase-dialog",
						data: {},
					},
				);

				thankyouDialogRef.afterClosed().subscribe((x) => {
					this.dialogRef.close(true);
				});
			},
			error: (err) => {
				this.ngxSpinnerService.hide();
				this.alertService.openSnackBar(`Error Submitting: ${err.error}`);
			},
		});
	}
}
